.management {
  font-family: $ff-circe;

  @include vp-639 {
    .container {
      // padding-left: 0;
      // padding-right: 0;
    }
  }
}

.management__wrapper {
  background-color: $color-default-white;
  border-radius: 100px;
  margin: 0 -40px;
  padding-top: 80px;
  padding-bottom: 75px;
  padding-left: 40px;
  padding-right: 40px;

  @include vp-1023 {
    margin: 0 -10px;
    padding-bottom: 48px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 30px;
  }
}

.management__title {
  text-align: center;
  margin-bottom: 48px;

  @include vp-1023 {
    text-align: left;
    margin-bottom: 32px;
  }

  @include vp-639 {
    max-width: 210px;
  }
}

.management__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, 203px);
  justify-content: space-between;
  gap: 28px;
  margin: 0;
  margin-bottom: 65px;
  padding: 0;
  // margin-right: 15px;
  list-style: none;

  @include vp-1023 {
    margin-bottom: 32px;
    gap: 32px 20px;
    grid-template-columns: repeat(auto-fit, 180px);
  }

  @include vp-639 {
    grid-template-columns: repeat(auto-fit, calc(50% - 10px));
  }
}

.management__item {
  display: flex;
  flex-direction: column;

  @include vp-1023 {
  }

  @include vp-639 {
    // align-items: center;
  }
}

.management__svg-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background-color: $color-white-smoke;

  @include vp-1023 {
    margin-bottom: 25px;
  }

  @include vp-639 {
    margin-bottom: 25px;
  }
}


.management__item-title {
  margin-bottom: 8px;
  font-style: normal;
  display: block;
  font-weight: 400;
  @include font-size(18px, 20px);
  letter-spacing: -0.02em;

  @include vp-1023 {
    // min-height: 52px;
  }
}

.management__item-text{
  margin: 0;
  font-style: normal;
  font-weight: 400;
  @include font-size(14px, 17px);
  letter-spacing: -0.03em;

  @include vp-1023 {
    max-width: 95%;
  }
}

.management__imgs {
  display: flex;
  margin-bottom: 64px;
  margin-left: -10px;
  margin-right: -10px;

  @include vp-1023 {
    margin-bottom: 34px;
    margin-left: -4px;
    margin-right: -4px;
  }

  @include vp-639 {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.management__img-wrapper {
  flex-grow: 1;
  max-width: 680px;
  max-height: 682px;
  border-radius: 30px;
  padding-left: 10px;
  padding-right: 10px;

  picture {
    margin: 0;
    padding: 0;
    display: block;
  }

  @include vp-1023 {
    padding-left: 4px;
    padding-right: 4px;
  }

  @include vp-639 {
    padding-left: 0;
    padding-right: 0;
  }
}

.management__img-wrapper img {
  width: 100%;
  object-fit: cover;
  border-radius: 30px;
  display: block;
  background-color: #6f6760;

  @include vp-639 {
    border-radius: 0;
  }
}

.management__explanation {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @include vp-1023 {
    display: block;
  }

  @include vp-639 {
    // margin-left: -4px;
    // margin-right: -4px;
  }
}

.management__why {
  // width: 50%;
  width: 100%;
  max-width: 426px;
  flex: 0 0 426px;
  margin-right: 133px;

  @include vp-1279 {
    margin-right: 64px;
  }

  @include vp-1023 {
    margin-right: 0;
  }
}

.management__why .h2 {
  margin-bottom: 32px;
  max-width: 315px;

  @include vp-1023 {
    margin-bottom: 8px;
  }
}

.management__why-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @include vp-1023 {
    display: none;
  }
}

.management__text {
  // width: 44%;
  width: 100%;
  flex: 0 1 auto;
  max-width: 647px;

  @include vp-1023 {
    // width: 56%;
  }

  @include vp-639 {
    width: 100%;
  }
}

.management__text button {
  display: none;
  margin-top: 32px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  @include vp-1023 {
    display: block;
  }

  @include vp-639 {
    display: block;
    margin: 0 auto;
  }
}

.management__text > p {
  margin-bottom: 50px;
  font-style: normal;
  font-weight: 400;
  @include font-size(24px, 34px);
  letter-spacing: -0.02em;
  margin-top: 0;

  @include vp-1023 {
    margin-bottom: 0;
    font-size: 18px;
  }

  @include vp-639 {
    margin-top: 5px;
    margin-bottom: 35px;
  }
}

.management__text-more {
  @include vp-1023 {
    display: block;
    padding-top: 24px;
  }
}

.management__text-more p {
  display: block;
  max-width: 538px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.29;
  opacity: 0.6;
  margin: 0 0 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @include vp-639 {
    max-width: 100%;
  }
}
