.modal-about {
  &__title {
    margin: 0 0 16px;
  }

  &__text {
    @include font-size(16px, 22px);

    p {
      margin: 0 0 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include vp-639 {
    &__title {
      font-size: 28px;
      line-height: 22px;
    }
  }
}
