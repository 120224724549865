.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: $color-default-black;
  border-radius: 100px;
  background-color: $color-default-white;
  border: 1px solid transparent;
  cursor: pointer;
  transition: opacity $trans-default, transform $trans-default, background-color $trans-default, border $trans-default, color $trans-default;;

  -webkit-tap-highlight-color: $color-transparent;

  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @include nohover {
    &:hover,
    &:focus {
      // opacity: 0.5;
    }
  }

  //
  &--size-default {
    padding: 8px 24px;
    @include font-size(16px, 24px);
    min-height: 48px;
  }
}

.btn--more {
  padding: 0;
  color: $color-very-light-grey;
  border: none;
  border-bottom: 1px solid $color-dark-gray;
  border-radius: initial;
  transition: color $trans-default, border $trans-default;

  @include nohover {
    &:hover,
    &:focus {
      color: $color-iris-blue;
      border-color: $color-iris-blue;
    }

    &:focus {
      background: rgba($color-iris-blue, 0.06);
      box-shadow: 0 0 0 5px rgba($color-iris-blue, 0.06);
    }
  }
}

.btn--black {
  background-color: $color-default-black;
  color: $color-default-white;
  font-weight: 700;
  letter-spacing: -0.02em;
  @include better-fonts;
  text-shadow: 1px 1px 0 rgba($color-default-black, 0.3);

  @include nohover {
    &:hover,
    &:focus {
      border-color: lighten($color-default-black, 40%);
      background-color: lighten($color-default-black, 40%);
    }
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba($color-default-black, 0.06);
  }
}

.btn--hollow {
  background-color: transparent;
  border-color: $color-default-black;
  color: $color-default-black;
  font-weight: 700;
  letter-spacing: -0.02em;

  @include nohover {
    &:hover,
    &:focus {
      border-color: $color-iris-blue;
      background-color: $color-iris-blue;
      color: $color-default-white;
    }
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba($color-iris-blue, 0.06);
  }
}

.btn--iris-blue {
  background-color: $color-iris-blue;
  color: $color-default-white;
  text-shadow: 1px 1px 0 rgba($color-default-black, 0.1);

  @include nohover {
    &:hover,
    &:focus {
      border-color: lighten($color-iris-blue, 3%);
      background-color: lighten($color-iris-blue, 3%);
    }
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba($color-iris-blue, 0.06);
  }
}
