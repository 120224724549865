.form {
  .container {
    // padding-left: 0;
    // padding-right: 0;
  }

  &__title {
    width: 100%;
    max-width: 463px;
    text-align: center;
    margin: 0 auto 32px;

    @include vp-639 {
      text-align: left;
      margin-left: 0;
      margin-right: 0;

      .modal-form & {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @include vp-474 {
      max-width: 278px;
    }

    @include vp-374 {
      max-width: 100%;
    }
  }

  &__block {
    margin: 0 -40px;
    padding: 80px 40px;
    background-color: $color-default-white;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;

    @include vp-1023 {
      margin: 0 -10px;
      padding: 48px 10px;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
    }
  }

  &__wrap {
    margin-left: auto;
    margin-right: auto;
    max-width: 820px;

    @include vp-1279 {
      //
    }

    form {
      display: flex;
      justify-content: space-between;

      @include vp-1023 {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 46%;
        min-width: 277px;
        margin: 0 auto;
      }

      @include vp-639 {
        width: 100%;
      }

      @include vp-374 {
        min-width: unset;
        width: 100%;
        transform: translateX(0);
      }
    }
  }

  .custom-input {
    width: 100%;
    flex: 0 1 auto;
    padding: 0;
    margin-right: 8px;

    @include vp-1023 {
      width: 100%;
      margin-bottom: 14px;
      margin-right: 0;
    }

    @include vp-639 {
      margin-bottom: 8px;
    }
  }

  &__btn {
    width: 204px;
    flex: 0 0 204px;

    @include vp-1023 {
      flex: 0 0 auto;
      width: 100%;
    }
  }
}
