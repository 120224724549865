.footer {
  padding: 18px 0;
  background-color: $color-default-black;

  @include vp-639 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

.footer * {
  color: $color-default-white;
}

.footer__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include vp-1279 {
    flex-wrap: wrap;
  }

  @include vp-639 {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.footer__logo {
  width: 147px;
  height: 44px;
  margin-right: 24px;

  picture {
    margin: 0;
    padding: 0;
    width: 100%;
    display: block;
  }

  img {
    display: block;
    width: 100%;
  }

  @include vp-1023 {
    margin-top: 0;
  }

  @include vp-639 {
    order: -1;
    margin-bottom: 32px;
  }
}

.footer__logo[href] {
  transition: opacity $trans-default;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      opacity: 0.5;
    }
  }
}

.footer__main-nav {
  margin: auto;
  // width: 58%;

  @include vp-1279 {
    width: 100%;
    order: 5;
    margin-top: 24px;
  }

  @include vp-1023 {
    // display: none;
  }

  @include vp-639 {
    order: 1;
    margin-top: 0;
  }
}

.footer__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  // margin-left: 75px;
  padding: 0;
  margin-bottom: -10px;
  list-style: none;

  @include vp-1279 {
    margin: auto;
    margin-right: auto;
    justify-content: center;
  }

  @include vp-639 {
    flex-direction: column;
  }
}

.footer__item {
  margin-bottom: 10px;
  margin-right: 32px;

  &:last-child {
    margin-right: 0;
  }

  @include vp-639 {
    margin-right: 0;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.footer__link {
  @include font-size(16px, 24px);
  letter-spacing: -0.02em;
  transition: color $trans-default;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      // opacity: 0.5;
      color: $color-iris-blue;
    }
  }

  &.is-active {
    opacity: 0.6;
  }

  @include vp-639 {
    @include font-size(24px, 35px);
  }
}

.footer__contacts {
  display: flex;
  margin-left: 24px;

  @include vp-1023 {
    margin-top: 12px;
  }

  @include vp-639 {
    margin-top: 32px;
    margin-left: 0;
    order: 5;
  }
}

.footer__tel,
.footer__mail {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    margin-right: 10px;
  }

  a {
    font-family: $ff-circe;
    @include font-size(15px, 22px);
    letter-spacing: -0.03em;
    transition: color $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-iris-blue;
      }
    }
  }
}

.footer__tel {
  margin-top: 2px;
  margin-right: 36px;

  @include vp-639 {
    margin-right: 34px;
  }
}
