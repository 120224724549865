.review {
  padding-top: 123px;
  padding-bottom: 80px;
  background-color: $color-white-smoke;
  overflow: hidden;

  @include vp-1023 {
    padding-top: 33px;
  }

  @include vp-639 {
    padding-top: 30px;
    padding-bottom: 17px;
  }
}

.review .container {
  @include vp-1023 {
    padding-left: 6px;
  }
}

.review__wrapper {
  max-width: 1320px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

// блок рейтинг
.review__raiting-wrapper {
  width: 45%;
  padding-left: 10px;
  display: flex;
  flex-wrap: wrap;

  @include vp-1023 {
    width: 100%;
    padding-left: 0;
  }
}

.review__raiting-text {
  width: 100%;
  align-self: flex-start;
}

.review__raiting-text p {
  margin: 0;
  font-size: 42px;
  line-height: 33px;
}

.review__raiting-text span {
  font-size: 105px;
  line-height: 117px;
}

.review__raiting-text span:nth-child(2) {
  @include vp-639 {
    display: inline-block;
    font-size: 42px;
    line-height: 33px;
    margin-left: -6px;
    height: 40px;
    margin-top: -10px;
    vertical-align: middle;
  }
}

.review__star-raiting {
  margin-top: -14px;
  height: 44px;
  width: 280px;

  @include vp-1023 {
    margin-top: 2px;
  }

  @include vp-639 {
    width: 250px;
    margin-top: 13px;
  }
}

.review__star-raiting svg {
  display: inline-block;
  fill: $color-kournikova;
  margin-left: 9px;
  margin-top: 20px;

  @include vp-1023 {
    margin-top: 3px;

    &:nth-child(1) {
      margin-left: 6px;
    }
  }

  @include vp-639 {
    width: 33px;
    height: 30px;
    margin-left: 10px;

    &:nth-child(1) {
      margin-left: 7px;
    }
  }
}

.review__count {
  margin-left: 23px;
  height: 44px;
  align-self: start;
  margin-top: 25px;

  @include vp-1023 {
    margin-left: 20px;
    margin-top: 1px;
  }

  @include vp-639 {
    margin-left: auto;
  }
}

.review__count span {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.03em;
  text-decoration: underline;

  @include vp-1023 {
    display: block;
    margin-top: 25px;
  }
}

.review__all-reviews {
  width: 100%;
  height: 55px;
  margin-top: 25px;

  @include vp-1023 {
    margin-top: 45px;
    margin-left: 3px;
  }

  @include vp-639 {
    display: none;
  }
}

.review__all-reviews a {
  display: inline-block;
  font-size: 32px;
  line-height: 47px;
  height: 55px;
  margin-left: 13px;
  vertical-align: middle;
  box-sizing: border-box;

  @include vp-1023 {
    margin-left: 10px;
  }
}

.review__all-reviews span {
  display: inline-block;
  position: relative;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin-right: 13px;
  vertical-align: middle;
  background-color: $color-default-black;
}

.review__all-reviews svg {
  position: absolute;
  top: 10px;
  left: 10px;
}

// блок отзыв
.review__text-wrapper {
  margin-top: 14px;
  position: relative;
  padding: 19px 85px 50px 21px;
  width: 50.5%;
  min-height: 220px;
  background-color: #fbfbfb;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;

  &::after,
  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 8%;
    border: 44px solid;
    border-top: 77px solid;

    @include vp-1023 {
      left: 9%;
    }
  }

  &::after {
    top: 100%;
    border-color: #c4c4c4 transparent transparent;

    @include vp-639 {
      left: 13%;
      border: 30px solid transparent;
      border-top: 51px solid #c4c4c4;
    }
  }

  &::before {
    top: 99.5%;
    border-color: #fbfbfb transparent transparent;
    z-index: 2;

    @include vp-639 {
      left: 13%;
      border: 30px solid transparent;
      border-top: 51px solid #fbfbfb;
    }
  }

  @include vp-1279 {
    width: 44%;
  }

  @include vp-1023 {
    width: 100%;
    margin-top: 44px;
    margin-left: 3px;
    padding: 17px 39px 48px 24px;
  }

  @include vp-639 {
    margin-top: 32px;
    padding: 15px 12px 5px 16px;
    min-height: 204px;
  }
}

.review__text-wrapper svg {
  fill: $color-kournikova;
}

.review__text-wrapper time {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.03em;
  margin-left: 42px;

  @include vp-639 {
    margin-left: 25px;
  }
}

.review__text-wrapper p {
  margin: 0;
  margin-top: 35px;
  font-size: 22px;
  line-height: 25px;
  letter-spacing: -0.03em;

  &:last-child {
    margin-top: 25px;
    max-width: 555px;

    @include vp-1023 {
      margin-top: 27px;
    }

    @include vp-639 {
      font-size: 18px;
      line-height: 20px;
      margin-top: 20px;
      letter-spacing: -0.03em;
    }
  }

  @include vp-639 {
    margin-top: 20px;
    letter-spacing: 0;
  }
}



// блок оплата
.review__blocks {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-left: -5px;
  // margin-right: -5px;
  width: calc(100% + 5px * 2);
  margin-top: 157px;

  @include vp-639 {
    margin-top: 48px + 48px;
    flex-direction: column;
    margin-left: 0;
    width: 100%;
  }
}

.review__blocks-item {
  margin-left: 5px;
  margin-right: 5px;
  width: 50%;
  border-radius: 50px;
  background-color: $color-default-white;
  padding: 48px;
  // min-height: 413px;


  @include vp-1023 {
    border-radius: 30px;
    padding: 40px;
  }

  @include vp-639 {
    margin-bottom: 4px;
    margin-left: 0;
    margin-right: 0;
    width: 100%;


    &:last-child {
      margin-bottom: 0;

    }
  }
}


.review__payment-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 56px;
  background-color: $color-default-white;
  box-sizing: border-box;
}

.review__blocks-title {
  margin: 0 0 8px;
}

.review__blocks-text {
  margin: 0;
  margin-top: 8px;
  @include font-size(16px, 22px);
  letter-spacing: -0.02em;
  max-width: 322px;
  width: 100%;

  @include vp-1023 {
    max-width: 300px;
  }
}

.review__image-wrapper {
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include vp-639 {
    margin-top: 32px;
  }
}

.review__cdek-wrapper {
  margin-right: 48px;

  @include vp-1023 {
    margin-right: 41px;
  }
}

.review__cdek-wrapper img {
  height: auto !important;

  @include vp-639 {
    width: 173px;
  }
}

.review__russia-mail-wrapper img {
  height: auto !important;

  @include vp-639 {
    width: 83px;
  }
}

// блок доставка
.review__delivery-wrapper {
  align-self: normal;
}

.review__delivery-wrapper .review__blocks-text {
  max-width: 208px;

  @include vp-639 {
    max-width: 300px;
  }
}

.review__map-wrapper {
  display: block;
  position: relative;
  left: 25px;
  margin-top: -66px;

  @include vp-1279 {
    left: 0;
    margin-top: -40px;
  }

  @include vp-1023 {
    margin: 0 auto;
    text-align: center;
  }

  @include vp-639 {
    width: 244px;
    margin-top: 32px;
  }
}

.review__map-wrapper img {
  display: block;

  @include vp-1023 {
    width: 295px;
    height: 164px;
    margin-left: auto;
    margin-right: auto;
  }
}
