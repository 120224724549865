.main-nav {
  // width: 58%;
  margin-left: auto;
  margin-right: auto;
  // margin-top: 26px;

  @include vp-1023 {
    position: fixed;
    top: -33px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 33px);
    padding: 142px 10px 10px;
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity $trans-default, visibility $trans-default, transform $trans-default;
    background-color: $color-default-black;
    z-index: 2;
  }

  .header.is-active & {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    // margin-left: 75px;
    margin-bottom: -10px;
    padding: 0;

    list-style: none;

    @include vp-1399 {
      margin-left: 0;
    }

    @include vp-1279 {
      justify-content: center;
    }

    @include vp-1023 {
      flex-direction: column;
      flex-wrap: nowrap;
      max-height: calc(58 * var(--vh, 1vh));
      margin-left: 0;
      overflow: auto;
    }

    @include vp-639 {
      margin-bottom: 9px;
    }
  }

  &__item {
    margin-bottom: 10px;
    // margin-right: 7.2%;
    margin-right: 32px;

    @include vp-1399 {
      margin-left: auto;
    }

    @include vp-1279 {
      margin-left: unset;
      // margin-right: 3.5%;
    }

    @include vp-1023 {
      margin-bottom: 32px;
      margin-right: 0;
    }

    @include vp-639 {
      margin-bottom: 12px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    @include font-size(16px, 24px);
    letter-spacing: -0.02em;
    transition: opacity $trans-default, color $trans-default;

    @include vp-1023 {
      color: $color-default-white;
    }

    @include vp-639 {
      @include font-size(24px, 35px);
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        // opacity: 0.5;
        color: $color-iris-blue;
      }
    }

    &.is-active {
      opacity: 0.6;
    }
  }

  &__btn {
    display: none;

    @include vp-639 {
      position: absolute;
      display: flex;
      bottom: 60px;
      width: calc(100% - 20px);
      padding: 10px 24px;
      @include font-size(24px, 35px);
    }

    @include vp-374 {
      padding: 16px 23px;
    }

    @include vp-299 {
      font-size: 22px;
      width: calc(100% - 20px);
    }
  }
}
