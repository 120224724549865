.modal-form {
  .modal__content {
    width: 612px;
    transform: translateY(-41px);
    border-radius: 20px;

    @include vp-639 {
      width: 100%;
    }
  }

  .container {
    padding: 0;
  }

  .form__block {
    padding-top: 0;
    padding-bottom: 0;
  }


  .form__title {
    margin-bottom: 32px;

    @include vp-639 {
      @include font-size(24px, 26px);
      max-width: 334px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 16px;
    }
  }

  form {
    flex-direction: column;

    @include vp-639 {
      width: 100%;
      min-width: unset;
    }
  }

  .form__wrap {
    max-width: 334px;
    margin-left: auto;
    margin-right: auto;
  }

  .form__wrap form {
    width: 100%;
  }

  .form .custom-input {
    width: 100%;
    margin-bottom: 8px;
  }

  .form__btn {
    width: 100%;
    flex: 0 0 auto;
  }

  // .modal__close-btn {
  //   top: 15px;
  //   right: 9px;
  // }
}
