.intro__inner {
  position: relative;
  padding: 0 40px;
  padding-top: 127px + 32px;
  margin: 0 -40px;
  background-color: $color-default-white;
  z-index: 1;

  @include vp-1399 {
    padding-top: 170px;
  }

  @include vp-1023 {
    padding: 0 10px;
    margin: 0 -10px;
    padding-top: 142px;
  }
}

.intro__title {
  margin-bottom: 24px;
  max-width: 816px;

  @include vp-1023 {
    margin-bottom: 35px;
  }

  @include vp-767 {
    max-width: 80%;
    @include font-size(28px, 26px);
  }

  @include vp-639 {
    max-width: 100%;
    margin-bottom: 40px;
  }
}

.intro__advantages {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  @include font-size(16px, 18px);
  letter-spacing: -0.02em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(110px, max-content));
  justify-content: space-between;
  column-gap: 70px;
  row-gap: 30px;
  margin-bottom: 40px;

  @include vp-710 {
    margin-bottom: 32px;
    grid-template-columns: repeat(auto-fit, minmax(80px, max-content));
    column-gap: 20px;
    row-gap: 25px;
    padding-right: 0;
  }
}

.intro__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 0;

  br {
    display: none;
  }

  @include vp-710 {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    max-width: 40px;

    br {
      display: inline;
    }
  }
}

.intro__icn {
  margin-right: 10px;
  display: inline-block;
  width: 32px;

  img {
    width: 100%;
    height: auto !important;
  }


  @include vp-710 {
    width: 25px;
    margin-bottom: 8px;
  }
}

.intro__break {
  display: none;

  @include vp-710 {
    display: initial;
    flex-basis: 100%;
    height: 0;
  }
}

.intro__item__text {
  margin: 0;
  padding: 0;
}




// SLIDER
.intro__picture {
  position: relative;
  width: calc(100% + 80px);
  height: 618px;
  background-color: #6f6760;
  margin-left: -40px;
  margin-right: -40px;

  @include vp-1439 {
    height: 42.92vw;
  }

  @include vp-1023 {
    margin-left: -10px;
    margin-right: -10px;
    width: calc(100% + 20px);
    height: 50vw;
  }

  @include vp-639 {
    height: 77.6vw;
  }
}

.intro__slide img {
  object-fit: cover;
  width: calc(100% + 80px);
  height: 618px;

  @include vp-1439 {
    height: 42.92vw;
  }

  @include vp-1023 {
    width: calc(100% + 20px);
    height: 50vw;
  }

  @include vp-639 {
    height: 77.6vw;
  }
}

.intro__slide source {
  object-fit: cover;
}

.intro__gift {
  position: absolute;
  bottom: 40px;
  right: 40px;
  z-index: 10;
  width: 238px;
  height: 238px;
  border-radius: 50%;
  padding-top: 152px;
  padding-left: 56px;
  padding-right: 10px;
  background-color: $color-default-white;
  font-family: $ff-circe;
  overflow: hidden;
  pointer-events: none;
  @include font-size(16px, 19px);

  @include vp-1279 {
    width: 140px;
    height: 140px;
    padding-top: 88px;
    padding-left: 32px;
    @include font-size(12px, 14px);
  }

  @include vp-1023 {
    bottom: 13px;
    right: 10px;
  }
}

.intro__gift-wrapper {
  // margin-left: -15px;
  // margin-bottom: -1px;
  // max-width: 165px;
  // max-height: 118px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
}

.intro__gift-wrapper img {
  object-fit: cover;
  width: 100%;
}

// .intro__slider {
//   .swiper-button-prev,
//   .swiper-button-next {
//     @media (hover: hover), screen and (min-width: 0\0) {
//       &:hover,
//       &:focus {
//         opacity: 0.6;
//       }
//     }

//     @include vp-639 {
//       display: none;
//     }
//   }
// }
