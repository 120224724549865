.work {
  padding-top: 160px;
  padding-bottom: 160px;
  background-color: $color-white-smoke;

  @include vp-959 {
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 48px;
    padding-bottom: 48px;
  }

  @include vp-639 {
    max-width: 100%;
  }
}

.work__title {
  text-align: center;
  margin-bottom: 48px;

  @include vp-959 {
    margin-bottom: 32px;
    text-align: left;
  }
}

.work__list {
  padding: 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
  position: relative;

  @include vp-959 {
    flex-direction: column;
  }
}

.work__item {
  width: 203px;
  position: relative;
  padding-left: 16px + 32px;
  margin-right: 24px;
  background: $color-white-smoke;

  &::before {
    content: '';
    position: absolute;
    right: 100%;
    width: 24px;
    height: 100%;
    background: $color-white-smoke;
    user-select: none;
  }

  &:last-child {
    margin-right: 0;
  }

  @include vp-1279 {
    width: 180px;
    margin-right: 12px;

    &::before {
      width: 12px;
    }
  }

  @include vp-959 {
    width: 100%;
    margin-bottom: 32px;
    margin-right: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.work__item-num {
  @include font-size(16px, 16px);
  font-weight: 700;
  width: 32px;
  height: 32px;
  padding-top: 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  color: $color-default-white;
  background-color: $color-default-black;
}

.work__item-title {
  margin: 0;
  @include font-size(24px, 35px);
  font-weight: 400;
  letter-spacing: -0.02em;
  display: block;
  position: relative;

  span {
    position: relative;
    z-index: 1;
    background: $color-white-smoke;

    &::before {
      content: '';
      position: absolute;
      left: 100%;
      top: 0;
      width: 24px;
      height: 100%;
      background: $color-white-smoke;
      pointer-events: none;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0%;
    top: 17px;
    width: 200%;
    height: 1px;
    background-image: linear-gradient(90deg, $color-default-black, $color-default-black 40%, transparent 50%, transparent 100%);
    background-size: 10px 2px;
    border: none;
    user-select: none;
  }

  .work__item:last-child &::before {
    content: none;
  }

  @include vp-1279 {
    span::before {
      width: 12px;
    }
  }

  @include vp-959 {
    &::before {
      content: none;
    }
  }
}

.work__item-text {
  display: block;
  @include font-size(14px, 16px);
  letter-spacing: -0.02em;
  margin: 0;
}

