.catalog-card {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  // margin-bottom: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: $color-default-white;
  border-radius: 30px;

  @include vp-767 {
    width: 68.5%;
    margin: 0 auto;
    padding-bottom: 11px;
    transform: translateX(-4px);
  }

  @include vp-639 {
    width: 102%;
  }

  &__img {
    position: relative;
    width: 52%;
    margin: 0 auto;
    height: 0;
    padding-bottom: calc(52% * 197 / 197);
    overflow: hidden;

    @include vp-1023 {
      width: 51%;
      padding-bottom: calc(51% * 197 / 197);
    }

    @include vp-639 {
      width: 50%;
      margin-bottom: -11px;
      padding-bottom: calc(50% * 164 / 164);
    }

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 91%;
      height: 91%;
      object-fit: cover;
    }
  }

  &__name {
    display: flex;
    align-items: center;
    min-height: 85px;
    margin: 0 auto 22px;
    padding: 0 41px;
    line-height: 1.2;

    @include vp-1023 {
      margin-bottom: 19px;
    }

    @include vp-639 {
      margin-bottom: 9px;
    }

    p {
      margin: 0;
      margin-right: auto;
      @include font-size(14px, 16px);
      letter-spacing: -0.02em;
      transform: translateY(-2px);

      @include vp-639 {
        font-size: 14px;
      }
    }

    p:first-child {
      margin-top: 26px;
      text-align: center;
    }
  }

  &__name-icon {
    position: relative;
    // min-width: 85px;
    // width: 25%;
    height: 64px;
    margin: 0 5% 0 12%;
    // padding-bottom: calc(25% * 82 / 82);
    width: 64px;
    overflow: hidden;

    // @include vp-1023 {
    //   min-width: 82px;
    // }

    // @include vp-639 {
    //   min-width: 68px;
    // }

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      fill: $color-default-white;
    }
  }

  &__desc {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    margin-bottom: 16px;
    border-top: 1px solid $color-whisper-2;
    border-bottom: 1px solid $color-whisper-2;
  }

  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;

    &--border-right {
      border-right: 1px solid $color-whisper-2;
    }
  }

  &__title {
    margin: 0;
    margin-top: 5px;
    padding: 0 13px 4px;
    @include font-size(12px, 18px);
    text-align: center;
    border-bottom: 1px solid $color-whisper-2;

    @include vp-639 {
      margin-top: 2px;
      padding-bottom: 2px;
      font-size: 13px;
    }
  }

  &__min-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    margin: 0;
    padding: 13px 13px 12px;
    list-style-type: none;

    @include vp-639 {
      padding-bottom: 8px;
    }
  }

  &__max-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0 auto;
    padding: 13px 13px 12px 5px;
    list-style-type: none;

    @include vp-639 {
      padding-bottom: 8px;
    }
  }

  &__price {
    margin: 0 0 13px;
    padding-top: 12px;
    text-align: center;
    @include font-size(14px, 21px);
    font-weight: 700;
    color: $color-denim;
    border-top: 1px solid $color-whisper-2;

    @include vp-639 {
      margin-bottom: 7px;
      padding-top: 10px;
      font-size: 13px;
    }
  }

  &__btn {
    width: calc(100% - 16px * 2);
    margin: 0 auto;
    font-size: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    min-height: 40px;

    @include vp-639 {
      width: 91%;
      padding-top: 9px;
      padding-bottom: 8px;
      font-size: 12px;
      letter-spacing: 0.3px;
    }
  }

  &__min-list-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 17px;
    font-size: 15px;
    color: $color-dark-gray;

    @include vp-639 {
      margin-bottom: 14px;
      font-size: 13px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      min-width: 25px;
      height: 24px;
      margin-right: 16px;

      @include vp-1023 {
        margin-right: 6px;
      }

      @include vp-639 {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
    }

    span {
      line-height: 1;
      align-self: center;
      margin-bottom: -2px;
    }
  }

  &__max-list-item {
    display: grid;
    grid-template-columns: 34% 1fr;
    align-items: flex-start;
    margin-bottom: 12px;
    font-size: 15px;

    @include vp-639 {
      grid-template-columns: 49% 1fr;
      margin-bottom: 9px;
      font-size: 13px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    span {
      width: 125px;
      line-height: 1;
      align-self: center;
      margin-bottom: -2px;

      @include vp-639 {
        width: unset;
        max-width: 100px;
      }
    }
  }

  &__icons {
    display: flex;
    margin-left: auto;
    margin-right: 12px;
  }

  &__icons-icon {
    min-width: 27px;

    & + & {
      margin-left: -8px;
    }

    @include vp-639 {
      min-width: unset;
      width: 23px;
      height: 23px;
      margin-right: 5px;
    }
  }
}
