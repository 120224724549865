.modal--success .modal__content {
  width: 613px;
  padding-top: 96px;
  padding-bottom: 96px;
  transform: translateY(-89px);

  @include vp-639 {
    width: 100%;
    padding: 77px 25px 85px;
  }

  @include vp-374 {
    padding: 77px 15px 85px;
  }
}


.modal-success-callback {
  text-align: center;

  &__title {
    margin-bottom: 16px;
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;

    @include vp-639 {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }

    @include vp-374 {
      width: 100%;
    }
  }

  .modal-success-callback__text {
    max-width: 260px;
    width: 100%;
    margin: 0 auto;
    @include font-size(16px, 22px);
    letter-spacing: -0.02em;

    @include vp-639 {
      width: 60%;
    }

    @include vp-374 {
      width: 90%;
    }
  }
}
