.title {
  margin: 0;
  font-family: $ff-circe;
  font-weight: 400;
  font-size: 52px;
  line-height: 0.91;
  color: $color-default-black;

  @include vp-1023 {
    font-size: 28px;
  }

  &--h2 {
    font-size: 42px;
    line-height: 0.81;

    @include vp-1023 {
      font-size: 28px;
    }
  }
}
