.catalog-download {
  &__wrapper {
    background: radial-gradient(50% 50% at 50% 50%, #00E6D7 0%, #00BFB3 100%);
    border-radius: 50px;
    padding: 60px 48px 60px 48px;
    position: relative;
    overflow: hidden;
  }

  &__bg {
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;

    img {
      width: 100%;
    }
  }

  &__title {
    color: $color-default-white;
    margin-bottom: 20px;
    font-weight: 400;
    display: block;
    letter-spacing: -0.04em;
    @include font-size(40px, 40px);
  }

  &__form-wrapper {
    width: 100%;
    max-width: 860px;
  }

  &__form {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    // flex-wrap: wrap;
    margin: 0 -4px -12px;
    padding: 0;
    position: relative;
    z-index: 1;
  }

  &__el {
    width: calc(100% / 3 - #{204px / 3});
    padding-right: 4px;
    padding-left: 4px;
    margin-bottom: 12px;

    &--send {
      width: 204px;
    }
  }

  &__btn {
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 48px;
  }

  .custom-input {
    width: 100%;
    flex: 0 0 auto;
    padding: 0;
  }


  // MEDIA
  @include vp-1279 {
    &__form {
      flex-wrap: wrap;
    }

    &__form-wrapper {
      // max-width: 720px;
      padding-right: 300px;
      max-width: 100%;
    }

    &__el {
      width: calc(100% / 3);
      min-width: 210px;

      &--send {
        min-width: 210px;
        width: calc(100% / 3);
      }
    }
  }

  @include vp-1023 {
    &__title {
      margin-bottom: 24px;
      max-width: 460px;
    }

    &__wrapper {
      padding: 32px;
      border-radius: 30px;
    }

    &_bg {
      max-width: 351px;
      width: 100%;
    }

    &__form-wrapper {
      padding-right: 0;
      max-width: 500px;
    }

    &__form {
      flex-direction: column;
      // max-width: 460px;
      width: 100%;
    }

    &__el {
      width: 100%;
      padding-left: 0;
      padding-right: 0;

      &--send {
        width: 100%;
      }
    }
  }

  @include vp-767 {
    &__wrapper {
      padding-bottom: 258px;
    }
  }

  @include vp-639 {
    padding-bottom: 32px;
  }
}
