.helper {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  transition: opacity $trans-default;
  position: relative;
  top: -0.5em;

  @include nohover {
    &:hover,
    &:focus {
      opacity: 0.5;
    }
  }

  svg {
    width: 12px;
    height: 12px;
  }
}
