.modal-calc {
  &__title {
    margin: 0 0 16px;
    text-align: center;
  }

  &__form-wrap {
    max-width: 334px;
    margin-left: auto;
    margin-right: auto;
  }

  &__cover {
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;

    img {
      display: block;
      width: 100%;
      height: auto !important;
    }
  }

  &__input {
    padding-bottom: 8px;
  }

  &__btn {
    width: 100%;
  }

  @include vp-639 {
    &__title {
      font-size: 28px;
      line-height: 22px;
    }
  }
}
