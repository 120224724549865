// Vendor
// ---------------------------------

@import "vendor/normalize";
@import "vendor/swiper"; // Swiper 7.4.1
@import "vendor/nouislider";
@import "vendor/tippy";

// Global
@import "variables";
@import "mixins";
@import "functions";
@import "global/fonts";
@import "global/reboot";
@import "global/utils";
@import "global/container";
@import "global/typography";

// UI
@import "ui/btn";
@import "ui/custom-input";
@import "ui/custom-toggle";
@import "ui/custom-select";
@import "ui/modal";
@import "ui/title";
@import "ui/helper";

// Modals
// @import "blocks/modal-portfolio";
@import "modals/modal-about";
@import "modals/modal-form";
@import "modals/modal-success-callback";
@import "modals/modal-buy";
@import "modals/modal-calc";

// Blocks
@import "blocks/header";
@import "blocks/main-nav";
@import "blocks/footer";
@import "blocks/data-accordion";
@import "blocks/stock";
@import "blocks/work";
@import "blocks/catalog-download";
@import "blocks/review";
@import "blocks/intro";
@import "blocks/data-tabs";
@import "blocks/cost";
@import "blocks/form";
@import "blocks/description";
@import "blocks/management";
@import "blocks/catalog-card";
@import "blocks/catalog";
@import "blocks/portfolio";


// ❗ обязательно используйте @media для отключения ховеров на тач устройствах
// но не используем для текстовых полей ( input, textarea )
/* @media (hover: hover), screen and (min-width: 0\0) {
  &:hover,
  &:focus {
    color: $color-default-white;
  }
} */

// адаптив пишем внутри каждого класса (смотрим container.scss)

// для фикса проблем с vh на iOS в сборке подключен скрипт
// используя vh на проекте задавайте их также как в примере в utils.scss

// для любых transition обязательно указывайте transition-property
// transition: $trans-default ❌ ---> transition: color $trans-default ✅
