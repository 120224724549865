.cost {
  margin-bottom: 80px;
  overflow: hidden;

  @include vp-1023 {
    margin-bottom: 17px;
  }

  @include vp-639 {
    margin-bottom: 33px;
  }

  &__title {
    max-width: 490px;
    margin-bottom: 64px;

    @include vp-1023 {
      max-width: 351px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }

    @include vp-639 {
      text-align: left;
      margin-bottom: 32px;
      margin-left: 0;
    }
  }

  &__wrap {
    position: relative;
    padding: 48px;
    background-color: $color-default-white;
    border-radius: 50px;

    @include vp-1023 {
      padding-left: 12px;
      padding-right: 12px;
      margin-left: -10px;
      margin-right: -10px;
      border-radius: 30px;
    }
  }

  &__controls-title,
  &__controls,
  &__text,
  &__info,
  &__input {
    width: 100%;
    max-width: calc(100% - (352px + 123px));

    @include vp-1279 {
      max-width: calc(100% - (254px + 64px));
    }

    @include vp-1023 {
      max-width: 100%;
    }
  }

  &__controls-title {
    margin: 0 0 16px;
    @include font-size(14px, 17px);
    letter-spacing: -0.02em;
    color: $color-dark-gray;

    @include vp-1023 {

    }
  }

  &__controls {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    z-index: 2;

    @include vp-639 {
      flex-direction: column;
    }
  }

  &__control {
    display: flex;
    align-items: center;
    // margin-right: 6%;
    margin-right: 48px;
    cursor: pointer;
    transition: opacity $trans-default;

    @include vp-1279 {
      margin-right: 43px;
    }

    @include vp-639 {
      margin-bottom: 16px;
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;

      @include vp-639 {
        margin-bottom: 0;
      }
    }

    input {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      padding: 0;
      // margin-right: 28px;
      margin-right: 12px;

      @include vp-1279 {
        // margin-right: 15px;
      }

      &::after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: $color-transparent;
        border-radius: 50%;
        transition: background-color $trans-default;
      }

      &.is-active {
        &:after {
          background-color: $color-default-black;
        }
      }
    }

    span {
      margin-top: 4px;
      @include font-size(16px, 16px);
      letter-spacing: -0.02em;
    }

    @include nohover {
      &:hover,
      &:focus {
        opacity: 0.5;
      }
    }
  }

  // .cost__content {
  //   position: unset;
  // }

  &__element {
    width: 100%;
    padding-top: 60px;

    @include vp-1023 {
      padding-top: 32px;
    }
  }


  &__img {
    position: absolute;
    top: 48px;
    right: 48px;
    // width: 32.2%;
    // height: auto;
    width: 352px;
    height: 352px;
    opacity: 0;
    transition: opacity $trans-default;
    user-select: none;

    &::before {
      content: '';
      display: block;
      height: 0;
      width: 100%;
      padding-bottom: 100%;
    }

    picture {
      display: block;
      margin: 0;
      padding: 0;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @include vp-1279 {
      width: 254px;
      height: 254px;
    }

    @include vp-1023 {
      display: none;
    }
  }

  [data-active-tab="0"] &__img--0,
  [data-active-tab="1"] &__img--1,
  [data-active-tab="2"] &__img--2 {
    opacity: 1;
  }

  &__tabs {
    overflow: visible;
  }

  &__text {
    margin: 0 0 16px;
    @include font-size(14px, 17 px);
    color: $color-dark-gray;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 0 12px;
    @include font-size(16px, 16px);
  }

  &__input {
    margin-bottom: 102px;
    height: 2px;

    @include vp-1023 {
      margin-bottom: 58px;
      margin-left: 10px;
      margin-right: 10px;
      width: calc(100% - 10px * 2);
    }

    .noUi-tooltip {
      font-size: 16px;
      line-height: 1;
      top: calc(100% + 8px);
      background-color: $color-transparent;

      @include vp-1023 {
        top: calc(100% + 4px);
      }
    }
  }

  &__checkboxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px 24px;
    grid-auto-flow: row;

    @include vp-1279 {
      grid-template-columns: repeat(2, 1fr);
    }

    @include vp-639 {
      display: block;
    }
  }

  &__element--1 &__checkboxes {
    grid-template-columns: 3fr 2fr 3fr;

    @include vp-1279 {
      grid-template-columns: 2fr 1fr;
    }
  }

  &__types {
    @include vp-1023 {
      width: 100%;
      flex: 0 0 100%;
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__types-title {
    margin: 0 0 16px;
    @include font-size(14px, 17px);
    color: $color-dark-gray;

    @include vp-1023 {
    }
  }

  &__types-wrap {
    display: flex;
    margin-left: -4px;
    margin-right: -4px;
  }

  &__type-control {
    padding-left: 4px;
    padding-right: 4px;
    width: 100%;
    flex: 0 1 auto;

    @include vp-639 {
      width: calc(100% / 3);
    }
  }

  &__submit {
    display: block;
    margin: 32px auto 0;
    padding-left: 32px;
    padding-right: 32px;

    @include vp-639 {
      width: 100%;
    }
  }

  .noUi-horizontal .noUi-handle {
    top: -6px;
  }
}


.cost-checkbox {
  padding-top: 16px;
  padding-bottom: 20px;
  border: 1px solid $color-whisper-2;
  border-radius: 16px;
  transition: border $trans-default;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: border $trans-default;
  -webkit-tap-highlight-color: $color-transparent;

  @include nohover {
    &:hover,
    &:focus {
      border-color: $color-iris-blue;
    }

    &:focus {
      box-shadow: 0 0 3px 1px rgba($color-iris-blue, 0.1);
    }
  }

  &__pic {
    display: block;
    width: 85px;
    height: 113px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &__check {
    display: inline-block;
    vertical-align: top;

    &::after {
      content: '';
      vertical-align: top;
      display: inline-block;
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid $color-default-black;
      border-radius: 5px;
    }
  }

  input:checked + &__check::after {
    background: url("../img/svg/icon-check.svg") no-repeat center center;
    background-size: 16px 13px;
  }
}
