@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

@function percent-from($all-width, $el-width) {
  @return #{$el-width / ($all-width / 100)}#{'%'};
}

@mixin font-size($size, $lineHeight) {
  font-size: $size;
  line-height: $lineHeight / $size;
}

@mixin better-fonts() { // For dark backgrounds
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin nohover {
  @media (hover: hover), screen and (min-width: 0\0) {
    @content;
  }
}

@mixin vp-1919 {
  @media (max-width: $vp-1920 - 1) {
    @content;
  }
}

@mixin vp-1439 {
  @media (max-width: $vp-1440 - 1) {
    @content;
  }
}

@mixin vp-1399 {
  @media (max-width: $vp-1400 - 1) {
    @content;
  }
}

@mixin vp-1333 {
  @media (max-width: $vp-1334 - 1) {
    @content;
  }
}

@mixin vp-1279 {
  @media (max-width: $vp-1280 - 1) {
    @content;
  }
}

@mixin vp-1023 {
  @media (max-width: $vp-1024 - 1) {
    @content;
  }
}

@mixin vp-959 {
  @media (max-width: $vp-960 - 1) {
    @content;
  }
}

@mixin vp-767 {
  @media (max-width: $vp-768 - 1) {
    @content;
  }
}

@mixin vp-767-min {
  @media (min-width: $vp-768) {
    @content;
  }
}

@mixin vp-710 {
  @media (max-width: $vp-711 - 1) {
    @content;
  }
}

@mixin vp-639 {
  @media (max-width: $vp-640 - 1) {
    @content;
  }
}

@mixin vp-639-min {
  @media (min-width: $vp-640) {
    @content;
  }
}

@mixin vp-474 {
  @media (max-width: $vp-475 - 1) {
    @content;
  }
}

@mixin vp-374 {
  @media (max-width: $vp-375 - 1) {
    @content;
  }
}

@mixin vp-299 {
  @media (max-width: $vp-300 - 1) {
    @content;
  }
}

// Mobile first

// @mixin vp-375 {
//   @media (min-width: $vp-375) {
//     @content;
//   }
// }

// @mixin vp-768 {
//   @media (min-width: $vp-768) {
//     @content;
//   }
// }

// @mixin vp-1024 {
//   @media (min-width: $vp-1024) {
//     @content;
//   }
// }

// @mixin vp-1280 {
//   @media (min-width: $vp-1280) {
//     @content;
//   }
// }

// @mixin vp-1440 {
//   @media (min-width: $vp-1440) {
//     @content;
//   }
// }

// @mixin vp-1920 {
//   @media (min-width: $vp-1920) {
//     @content;
//   }
// }

// ---------------------------------

// не нужно добавлять свои миксины для ресета списков, обнуления кнопок и прочие
// этим вы усложняете работу команды, проверку вашего кода и будущую поддержку проекта
