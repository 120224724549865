.stock {
  padding-top: 88px;
  background-color: $color-white-smoke;

  @include vp-1023 {
    padding-top: 48px;
  }
}

.stock__title {
  text-align: center;
  margin-bottom: 48px;

  @include vp-1023 {
    margin-bottom: 32px;
  }
}

.stock__list {
  margin: 0 auto;
  padding: 0;
  max-width: 1320px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  list-style: none;

  @include vp-1399 {
    max-width: 880px;
  }

  @include vp-1023 {
    max-width: 623px;
  }

  @include vp-639 {
    max-width: 355px;
    flex-direction: column;
  }
}

.stock__item {
  width: 440px;
  height: 440px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 77px;
  box-sizing: border-box;
  background-color: $color-default-white;
  overflow: hidden;

  &--sell {
    background: radial-gradient(50% 50% at 50% 50%, #00E6D7 0%, #00BFB3 100%);
    color: $color-default-white;
  }

  @include vp-1399 {
    &--sell {
      order: 4;
      margin-top: -57px;
    }
  }

  @include vp-1023 {
    width: 205px;
    height: 205px;
    padding-top: 30px;

    &--sell {
      order: inherit;
      margin-top: 0;
    }
  }

  @include vp-639 {
    width: 351px;
    padding-top: 61px;
    max-width: 100%;
    height: 351px;
    width: 100%;
    align-self: center;
    justify-content: center;
    padding-top: 0;
  }
}


.stock__item-img,
.stock__sell {
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  order: 1;
  margin: 0 auto 24px;

  @include vp-1023 {
    height: 65px;
    margin-bottom: 12px;
  }

  @include vp-639 {
    height: auto;
    margin-bottom: 20px;
  }
}

.stock__sell {
  order: 1;
  font-size: 105px;
  line-height: 1;
  text-align: center;
  align-items: center;
  padding-top: 10%;

  @include vp-1023 {
    font-size: 48px;
    line-height: 55px;
  }

  @include vp-639 {
    font-size: 104px;
    line-height: 117px;
    height: auto;
    margin-bottom: 0;
  }
}

.stock__item-img {
  text-align: center;

  picture {
    width: 100%;
    height: 100%;
    display: block;
    margin: 0;
    padding: 0;
  }

  img {
    margin-left: auto;
    margin-right: auto;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    display: block;
  }

  @include vp-639 {
    max-width: 190px;
  }
}


.stock__item-title {
  margin: 0;
  order: 2;
  @include font-size(18px, 20px);
  font-weight: 400;
  text-align: center;

  @include vp-1023 {
    font-size: 14px;
    line-height: 16px;
    margin-top: 16px;
  }

  @include vp-639 {
    margin-top: 3px;
    @include font-size(18px, 20px);
  }
}

.stock__item-text {
  margin: 0 auto;
  order: 3;
  max-width: 212px;
  width: 100%;
  margin-top: 8px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.03em;
  text-align: center;

  @include vp-1023 {
    font-size: 12px;
    line-height: 13px;
    max-width: 122px;
    margin-top: 4px;
  }

  @include vp-639 {
    max-width: 204px;
    font-size: 14px;
    line-height: 16px;
    margin-top: 8px;
  }
}
