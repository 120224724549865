.portfolio {
  padding-top: 80px;
  padding-bottom: 80px;
  overflow: hidden;

  &__head {
    margin-bottom: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    margin-right: 24px;
  }

  &__controls-arrow {
    border: 0;
    padding: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background: transparent;
    transition: color $trans-default;
    display: inline-block;
    vertical-align: top;

    &:hover {
      color: #00E5D7;
      opacity: 0.8;
    }
  }

  &__controls-icn {
    width: 40px;
    height: 40px;
    fill: transparent;
  }

  &__body {
    margin-left: -10px;
    margin-right: -10px;
  }

  &__item {
    padding-left: 10px;
    padding-right: 10px;
  }

  &__content {
    width: 100%;
    padding-bottom: 113.637%;
    position: relative;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    background: $color-gainsboro;
    border-radius: 30px;
    position: absolute;
    left: 0;
    top: 0;
  }

  @include vp-1023 {
    padding-top: 48px;
    padding-bottom: 48px;

    &__head {
      margin-bottom: 32px;
    }
    &__body {
      margin-left: -4px;
      margin-right: -10px;
    }

    &__item {
      width: 320px;
      height: 360px;
      padding-bottom: 0;
      padding-left: 4px;
      padding-right: 4px;
    }
  }


  @include vp-639 {

  }
}



      // .portfolio__head
      //   h2.portfolio__title Портфолио
      // .portfolio__body
      //   .portfolio__list-wrapper.swiper.js-slider-portfolio
      //     .portfolio__list.swiper-wrapper
      //       .portfolio__item.swiper-slide
      //         .portfolio__content
      //           .portfolio__img
