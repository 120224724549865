.custom-select {
  position: relative;
  width: 250px;
  padding: 30px 0;

  &__label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    transition: transform $trans-default, opacity $trans-default;
    pointer-events: none;
  }

  &__placeholder {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    transition: transform $trans-default, opacity $trans-default;
    pointer-events: none;
  }

  select {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;

    white-space: nowrap;

    border: 0;

    clip: rect(0 0 0 0);
    clip-path: inset(100%);
  }

  &__button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 5px 10px;

    background-color: $color-transparent;
    border: 2px solid rgba($color-default-black, 0.2);
    border-radius: 5px;
    outline: none;
    cursor: pointer;

    transition: border $trans-default;

    &:hover {
      border: 2px solid rgba($color-default-black, 0.5);
    }

    &:focus {
      border: 2px solid rgba($color-default-black, 0.8);
    }
  }

  &__list {
    position: absolute;
    bottom: 32px;
    z-index: 1;
    width: 100%;
    max-height: 100px;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    list-style: none;
    background-color: $color-default-white;
    border: 2px solid $color-default-black;
    transform: translateY(100%);
    visibility: hidden;
    opacity: 0;

    transition: visibility $trans-default, opacity $trans-default;
  }

  &__item {
    padding: 5px;

    outline: none;
    cursor: pointer;

    transition: background-color $trans-default;

    &[aria-selected="true"] {
      background-color: rgba($color-default-white, 0.2);

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          background-color: rgba($color-default-white, 0.2);
        }
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        background-color: rgba($color-default-white, 0.4);
      }
    }
  }

  &__error {
    position: absolute;
    bottom: 0;
    left: 0;

    color: $color-torch-red;

    opacity: 0;

    transition: opacity 0.1s ease;
  }

  &__text {
    flex-shrink: 0;
    width: 80%;
    overflow: hidden;

    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;

    opacity: 0;

    transition: opacity $trans-default 0.1s;

    br {
      display: none;
    }
  }

  &__icon {
    flex-shrink: 0;
    width: 14px;
    height: 10px;
    margin-left: auto;
    color: $color-default-black;

    transition: transform $trans-default;

    svg {
      fill: transparent;
      display: inline-block;
      vertical-align: top;
    }
  }

  &.open-up {
    .custom-select__list {
      bottom: auto;
      top: 0;
      transform: translateY(calc(-100% + 30px));
    }
  }

  &.is-open {
    .custom-select__list {
      visibility: visible;
      opacity: 1;
    }

    .custom-select__icon {
      transform: rotate(180deg);
    }

    .custom-select__label {
      opacity: 0;
    }
  }

  &.not-empty {
    .custom-select__text {
      opacity: 1;
    }

    .custom-select__label {
      opacity: 0;
    }

    .custom-select__placeholder {
      opacity: 0;
    }
  }

  &.is-invalid {
    .custom-select__error {
      opacity: 1;
    }

    .custom-select__button {
      border: 2px solid $color-torch-red;

      &:focus,
      &:hover {
        border: 2px solid rgba($color-torch-red, 0.5);
      }
    }
  }

  /* &.is-valid {
    .custom-select__button {
      border: 2px solid $color-eucalyptus;

      &:focus,
      &:hover {
        border: 2px solid rgba($color-eucalyptus, 0.5);
      }
    }
  } */

  &--placeholder {
    .custom-select__label {
      transform: translateY(calc(-50% - 40px));
      opacity: 1;
    }

    &.is-open .custom-select__label {
      opacity: 1;
    }

    &.not-empty .custom-select__label {
      opacity: 1;
    }
  }

  &.slide-up.not-empty,
  &.slide-up.is-open {
    .custom-select__label {
      transform: translateY(calc(-50% - 40px));
      opacity: 1;
    }

    .custom-select__text {
      opacity: 1;
    }
  }
}
