.h1 {
  @include font-size(48px, 48px);
  font-weight: 400;
  display: block;
  letter-spacing: -0.04em;
  cursor: default;
  margin: 0;
}

.h2 {
  @include font-size(40px, 40px);
  font-weight: 400;
  display: block;
  letter-spacing: -0.04em;
  cursor: default;
  margin: 0;
}
