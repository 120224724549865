.header {
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: $color-white-smoke;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include vp-1023 {
      justify-content: flex-start;
    }
  }

  &__logo {
    width: 147px;
    height: 45px;

    @include vp-1023 {
      position: relative;
      width: 124px;
      height: 38px;
      margin-left: 13px;
      z-index: 3;
    }

    @include vp-639 {
      margin: 0 auto;
      transform: translateX(-19%);
    }

    svg {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__logo[href] {
    transition: opacity $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        opacity: 0.5;
      }
    }
  }

  &__block {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $color-white-smoke;

    @include vp-1023 {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }

  &__nav-block {
    display: flex;
    align-items: center;
    padding-top: 18px;
    padding-bottom: 18px;
    background-color: $color-default-white;
    border-bottom: 1px solid $color-gainsboro;

    @include vp-1023 {
      padding-top: 15px;
      padding-bottom: 16px;
    }

    @include vp-639 {
      padding-top: 7px;
      padding-bottom: 7px;
      border: none;
    }
  }

  &__statistic {
    @include font-size(14px, 14px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    font-size: 15px;
    letter-spacing: -0.5px;

    @include vp-1023 {
      font-size: 14px;
      margin-left: 4px;
      letter-spacing: -0.4px;
    }

    @include vp-374 {
      font-size: 12px;
    }

    svg {
      display: inline-block;
      vertical-align: middle;
      fill: $color-iris-blue;
      margin-left: 16px;
      margin-right: 5px;
      width: 19px;
      height: 20px;
      color: $color-iris-blue;

      @include vp-1023 {
        margin-left: 6px;
      }

      @include vp-639 {
        margin-left: 9px;
        margin-right: 6px;
      }

      @include vp-374 {
        width: 16px;
        height: 15px;
      }
    }

    span {
      @include vp-1023 {
        margin-top: 3px;
      }
    }
  }

  &__contacts {
    display: flex;
    margin-left: auto;

    @include vp-639 {
      position: absolute;
      height: 37px;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: -37px;
      left: 0;
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 0 10px;
      background-color: $color-white-smoke;
    }

    @include vp-374 {
      overflow: scroll;
      scrollbar-width: none;
      scrollbar-color: $color-transparent;
    }

    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }
  }

  &__tel,
  &__mail {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    letter-spacing: -0.03em;

    @include vp-1023 {
      letter-spacing: -0.5px;
    }

    @include vp-639 {
      transform: translateX(7px);
    }

    svg {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;

      @include vp-639 {
        margin-top: 1px;
      }
    }

    a {
      @include font-size(14px, 14px);
      transition: opacity $trans-default, color $trans-default;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          // opacity: 0.5;
          color: $color-iris-blue;
        }
      }
    }
  }

  &__tel {
    margin-top: 2px;
    margin-right: 36px;
    letter-spacing: -0.7px;

    @include vp-1023 {
      margin-right: 18px;
      letter-spacing: -0.7px;
    }

    @include vp-639 {
      margin-top: 3px;
      margin-right: 8%;
      transform: translateX(0);
    }

    svg {
      margin-top: 0;
      margin-right: 11px;

      @include vp-639 {
        margin-top: 0;
      }
    }
  }

  &__btn {
    margin-top: 1px;
    outline: none;
    -webkit-tap-highlight-color: $color-transparent;
    z-index: 3;
    border: none;
    min-height: 40px;

    @include vp-1023 {
      margin-top: 2px;
      margin-left: auto;
      transition: background-color $trans-default, color $trans-default;
    }

    @include vp-639 {
      display: none;
    }

    .header.is-active & {
      background-color: $color-iris-blue;
      color: $color-default-white;
    }
  }

  &__toggle {
    display: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 41px;
    height: 41px;
    padding: 0;
    border: none;
    outline: none;
    border-radius: 8px;
    background-color: $color-white-smoke;
    transition: opacity $trans-default;
    z-index: 3;
    position: relative;

    .header.is-active & {
      background: rgba(244, 243, 243, 0.2);
    }

    @include vp-1023 {
      display: flex;
    }

    @include nohover {
      &:hover,
      &:focus {
        opacity: 0.5;
      }

      .header.is-active &:hover,
      .header.is-active &:focus {
        opacity: 0.8;
      }
    }

    .header__burger-icon {
      position: absolute;
      display: flex;
      color: $color-default-black;
      transition: opacity $trans-default, visibility $trans-default;

      .header.is-active & {
        opacity: 0;
        visibility: hidden;
      }
    }

    .header__close-icon {
      position: absolute;
      display: flex;
      opacity: 0;
      visibility: hidden;
      color: $color-default-white;
      transition: opacity $trans-default, visibility $trans-default;

      .header.is-active & {
        opacity: 1;
        visibility: visible;
      }
    }

    svg {
      fill: $color-default-black;
    }
  }
}
