.catalog {
  &__controls {
    display: none;

    @include vp-767 {
      display: block;
    }

    .custom-select {
      width: auto;
      max-width: 100%;
      padding-top: 0;
      padding-bottom: 0;
    }

    .custom-select__button {
      display: inline-flex;
      vertical-align: top;
      position: relative;
      height: auto;
      padding: 16px 0 16px 0;
      background-color:transparent;
      border-color: transparent;
      z-index: 2;
      -webkit-tap-highlight-color: $color-transparent;
      transition: opacity $trans-default;
      justify-content: flex-start;
      z-index: 3;
      width: auto;
      color:  $color-default-black;

      @include nohover {
        &:hover,
        &:focus {
          opacity: 0.5;
          color: $color-iris-blue;
        }
      }
    }

    .custom-select__text {
      color: currentColor;
      font-size: 40px;
      line-height: 1;
      width: auto;
      transition: color $trans-default;
    }

    .custom-select__list {
      width: 100%;
      max-height: 220px;
      border: 1px solid $color-gainsboro;
      transform: translate(0, -32px);
      z-index: -1;
      border-radius: 10px;
      background-color: #fff;
      z-index: 2;
      bottom: auto;
      top: 100%;
      transition: all $trans-default;
    }

    .custom-select.is-open .custom-select__list {
      transform: translate(0, -16px);
    }

    .custom-select__icon {
      margin-left: 12px;
      margin-bottom: -1px;
      color: currentColor;
      transition: color $trans-default, transform $trans-default;
    }

    .custom-select__item {
      padding: 20px;
      font-size: 28px;
      transition: opacity $trans-default;

      @include nohover {
        &:hover,
        &:focus {
          opacity: 0.5;
        }
      }
    }
  }

  &__content {
    @include vp-767-min {
      height: auto !important;
    }
  }

  &__element {
    position: relative;
    visibility: visible;
    opacity: 1;
    display: block;
    transition: all $trans-default;

    @include vp-767 {
      width: 100%;
      position: absolute;
      visibility: hidden;
      opacity: 0;
      display: unset;
    }
  }

  &__title {
    margin: 0;
    margin-bottom: 16px;
    @include font-size(16px, 19px);
    color: $color-silver;
    font-weight: 400;

    @include vp-767 {
      margin-bottom: -2px;
    }

    @include vp-639 {
      margin-bottom: -4px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 25%));
    // grid-gap: 5%;
    grid-gap: 0 56px;
    margin: 0 0 8px;
    padding: 0;
    list-style-type: none;

    @include vp-1279 {
      grid-template-columns: repeat(2, 1fr);
    }

    @include vp-767 {
      width: 100%;
      grid-template-columns: 1fr 1fr;
      margin: 0 auto 32px;
    }

    @include vp-639 {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    margin-bottom: 22px;

    @include vp-767 {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;

      &::after {
        content: "";
        position: absolute;
        bottom: 0.5em;
        width: 100%;
        height: 1px;
        background-color: $color-gainsboro;
        z-index: 1;
      }
    }

    @include vp-639 {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__name {
    margin-bottom: 16px;

    @include vp-767 {
      display: none;
    }
  }

  &__feature-left {
    @include font-size(16px, 24px);
    letter-spacing: -0.02em;
    font-weight: 700;
    margin: 0;
    margin-right: -30%;
    width: 54%;
    vertical-align: top;
    // overflow: hidden;
    display: inline-block;
    position: relative;

    & > span {
      display: inline-block;
      vertical-align: middle;
      background: $color-white-smoke;
      position: relative;
      padding-right: 16px;
    }

    @include vp-1439 {
      width: 50%;
    }

    @include vp-767 {
      display: flex;
      width: unset;
      background-color: $color-white-smoke;
      z-index: 2;
      overflow: visible;
    }

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      bottom: 0.5em;
      left: 0;
      width: calc(100% - 16px);
      vertical-align: baseline;
      border-bottom: 1px solid $color-gainsboro;
      z-index: -1;

      @include vp-767 {
        content: none;
      }
    }
  }

  &__helper {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-33%);

    @include vp-767 {
      transform: translateY(-15%);
    }
  }

  &__feature-left-button {
    position: relative;
    top: -15px;
    right: -44px;
    width: 22px;
    height: 22px;
    padding: 0;
    cursor: pointer;
    border: none;
    background-color: $color-transparent;
    -webkit-tap-highlight-color: $color-transparent;
    transition: opacity $trans-default;
    z-index: 2;

    @include vp-1023 {
      right: calc(-68px + (-44 + 68) * ((100vw - 768px) / (1023 - 768)));
    }

    @include vp-767 {
      right: -21px;
    }

    @include vp-374 {
      right: calc(-34px + (-21 + 34) * ((100vw - 320px) / (374 - 320)));
    }

    @include nohover {
      &:hover,
      &:focus {
        opacity: 0.5;
      }
    }

    & + .catalog__feature-right {
      margin-left: 22.5%;
      transform: translateY(-6px);

      @include vp-767 {
        transform: translateY(0);
      }
    }
  }

  &__feature-right {
    display: inline-block;
    max-width: 170px;
    // width: calc(100% - 54px);
    max-height: 24px;
    overflow: auto;
    margin: 0;
    margin-left: 29%;
    // padding-left: 3px;
    @include font-size(16px, 24px);
    letter-spacing: -0.02em;
    vertical-align: bottom;
    scrollbar-width: none;
    background: $color-white-smoke;
    scrollbar-color: $color-transparent;
    position: relative;

    @include vp-767 {
      display: flex;
      background-color: $color-white-smoke;
      padding-left: 16px;
      z-index: 2;
    }

    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    align-items: stretch;
    margin-bottom: 80px;

    @include vp-1279 {
      grid-template-columns: repeat(2, 1fr);
    }

    @include vp-767 {
      grid-template-columns: 1fr;
      grid-gap: 8px;
      margin-bottom: 0;
    }

    @include vp-639 {
      grid-gap: 13px;
    }
  }

  &__element:last-child &__cards {
    margin-bottom: 0;
  }
}
