.description {
  margin-bottom: 80px;

  @include vp-1023 {
    margin-bottom: 48px;
  }

  &__intro {
    @include font-size(16px, 19px);
    display: flex;
    justify-content: space-between;
    margin: 0 -40px;
    padding: 24px 40px;
    background-color: $color-default-white;
    border-bottom: 1px solid rgba($color-default-black, 0.2);

    @include vp-1023 {
      margin: 0 -10px;
      padding-left: 10px;
      padding-right: 10px;
    }

    @include vp-639 {
      flex-direction: column;
      padding-bottom: 19px;
    }
  }

  &__intro-text {
    // width: 47.4%;
    max-width: 538px;
    margin: 0 0 0 32px;

    @include vp-639 {
      width: 100%;
      margin: 0;
    }
  }

  &__terms {
    max-width: 226px;
    width: 100%;
    margin: 0;
    flex: 0 0 auto;

    @include vp-639 {
      @include font-size(20px, 22px);
      max-width: 246px;
      letter-spacing: -0.02em;
      margin-bottom: 12px;
    }
  }


  // TABS
  &__tabs {
    margin: 0 -40px;
    padding: 48px 40px 80px;
    background-color: $color-default-white;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;

    @include vp-1023 {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin: 0 -10px;
    }

    @include vp-959 {
      padding: 20px 10px 48px;
    }
  }

  &__controls {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &__control {
    border: 1px solid  $color-default-black;
    margin-right: 16px;
    padding: 7px 24px;
    min-height: 38px;
    transition: color $trans-default, background-color $trans-default, border-color $trans-default;
    min-width: 243px;
    font-weight: 700;

    &.is-active {
      color: $color-default-white;
      background-color: $color-iris-blue;
      border-color: $color-transparent;
      pointer-events: none;
    }

    @include nohover {
      &:hover,
      &:focus {
        color: $color-iris-blue;
        border-color: $color-iris-blue;
      }

      &.is-active:hover,
      &.is-active:focus {
        color: $color-default-white;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    @include vp-959 {
      margin-right: 4px;
      min-width: 1px;
      padding: 2px 14px 0;
    }
  }

  &__element {
    padding-top: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    @include vp-1023 {
      padding-top: 73px;
    }

    @include vp-959 {
      flex-direction: column;
    }
  }

  &__img-wrapper {
    width: percent-from(1320, 642);
    margin-right: percent-from(1320, 250);
    align-self: center;

    @include vp-1023 {
      margin-right: 120px;
    }

    @include vp-959 {
      width: 100%;
      max-width: 352px;
      margin-bottom: 38px;
      margin-right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__img {
    position: relative;
    height: 0;
    padding-bottom: 100%;

    @include vp-1023 {
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      top: 0;
      // bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      // object-fit: cover;
    }
  }

  &__360 {
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 100%;
    width: 112px;
    height: 53px;
    margin: auto;
    padding: 0;
    z-index: 1;
    transform: translate(-50%, -50%);
    background-color: $color-transparent;
    -webkit-tap-highlight-color: $color-transparent;
    transition: opacity $trans-default;
    border: none;
    outline: none;


    @include nohover {
      &:hover,
      &:focus {
        opacity: 0.5;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto !important;
    }

    picture {
      display: block;
      width: 100%;
    }

    @include vp-959 {
      top: 0;
      left: 50%;
      width: 107px;
      height: 52px;
    }
  }

  &__list {
    width: 290px;
    margin: 0;
    padding: 0;
    list-style-type: none;

    @include vp-959 {
      width: 100%;
      max-width: 352px;
      margin-right: auto;
      margin-left: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
    }

    @include vp-639 {
      max-width: 100%;
      justify-content: flex-start;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    @include vp-1023 {
      margin-bottom: 17px;
    }

    @include vp-959 {
      align-items: center;
      flex-direction: column;
      width: 50%;
      margin-bottom: 40px;
      text-align: center;
    }

    @include vp-639 {
      text-align: left;
      align-items: flex-start;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 71px;
    height: 68px;
    margin-right: 24px;
    border: 1px dashed $color-default-black;
    border-radius: 10px;
    overflow: hidden;
    flex: 0 0 auto;

    @include vp-959 {
      width: 62px;
      height: 59px;
      margin-bottom: 14px;
      margin-right: 0;
    }

    img {
      width: 100%;
      max-width: 45px;
      height: 100%;
      object-fit: cover;

      @include vp-1023 {
        max-width: 37px;
      }
    }
  }

  &__text {
    width: 100%;
    max-width: 150px;
    margin: 0;
    @include font-size(16px, 19px);

    @include vp-959 {
      max-width: 160px;
    }
  }
}
