.custom-input,
.custom-textarea {
  position: relative;
  display: flex;
  width: 100%;
  padding: 0 0 20px;

  label {
    display: flex;
    align-items: center;
    width: 100%;
  }

  input,
  textarea {
    width: 100%;
    flex-grow: 1;
    background: $color-white-smoke2;
    color: $color-text;
    outline: none;
    transition: border $trans-default;

    &:hover {
      border-color: rgba($color-default-black, 0.3);
    }

    &:focus {
      border-color: rgba($color-default-black, 0.6);
    }

    &::placeholder {
      opacity: 1;
      color: $color-dark-grey;
    }
  }

  &--size-default {
    input,
    textarea {
      @include font-size(18px, 27px);
      letter-spacing: -0.03em;
      border: 1px solid transparent;
      border-radius: 100px;
      padding: 11px 16px 9px;
      min-height: 48px;
    }
  }

  &__error {
    position: absolute;
    right: 0;
    bottom: -5px;
    color: $color-torch-red;
    opacity: 0;
    transition: opacity $trans-default;
  }

  &.is-invalid {
    input {
      border-color: $color-torch-red;

      &:focus,
      &:hover {
        border-color:  rgba($color-torch-red, 0.5);
      }
    }

    .custom-input__error {
      opacity: 1;
    }
  }

  // &.is-valid {
  //   input {
  //     border: 2px solid $color-malachite;

  //     &:focus,
  //     &:hover {
  //       border: 2px solid rgba($color-malachite, 0.5);
  //     }
  //   }
  // }
}
